<template>
  <page-structure title="contacts" :loading="loading">
    <!-- Info -->
    <div
      class="col-md-6 mb-60 animate-box fadeInUp animated"
      data-animate-effect="fadeInUp"
    >
      <ContactInfo />
    </div>

    <!-- Contact Form -->
    <div
      class="col-md-6 mb-60 animate-box fadeInUp animated"
      data-animate-effect="fadeInUp"
    >
      <div>
        <h4>Drop me a line!</h4>
      </div>
      <ContactForm />
    </div>
  </page-structure>
</template>

<script>
import ContactInfo from "@/components/contacts/ContactInfo";
import ContactForm from "@/components/contacts/ContactForm";
export default {
  name: "contacts",
  data: () => {
    return { loading: false };
  },
  components: {
    ContactInfo,
    ContactForm,
  },
  async mounted() {
    this.$emit("setsocial", false);
  },
  metaInfo: {
    title: "About",
    titleTemplate: "%s - moldoVAN",
    htmlAttrs: {
      lang: "en",
    },
    
  },
};
</script>

<style lang="scss">
@import "@/assets/css/contactPage";
</style>
